<template>
  <v-dialog width="400" v-model="dialog" :fullscreen="$vuetify.breakpoint.xs">
    <v-card>
      <v-card-title>
        {{ $t("new", { name: $tc("inventorys.product") }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false">
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <inventorys-form
          ref="form"
          :title="$t('new', { name: $tc('inventory') })"
          :inventory="inventory"
          @validated="saveInventory()"
          @cancel="dialog = false"
        ></inventorys-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
//import constants from "@/constants";
export default {
  name: "InventoryNew",
  components: { InventorysForm: () => import("@/components/inventory/Form") },
  data() {
    return {
      inventory: {},
      dialog: false,
    };
  },
  watch: {
    dialog: {
      handler() {
        console.log(this.dialog);
        this.$refs.form.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("inventory", ["addInventory"]),
    saveInventory() {
      //console.log(this.inventory);

      let formData = new FormData();
      Object.keys(this.inventory).forEach((key) => {
        if (this.inventory[key]) formData.append(key, this.inventory[key]);
      });
      this.addInventory({ inventory: formData }).then((response) => {
        if (response.success) {
          this.errors.clear();
          this.$alert(this.$t("inventory_create_success"), "", "");
          this.$emit("refresh");
          this.dialog = false;
          //tengo que mirarme router.back
        } else {
          this.$alert(this.$t("inventory_create_false"), "", "");
        }
      });
    },
  },
};
</script>
